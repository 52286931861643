<template>
  <section id="dashboard-ecommerce">
    <div class="sec1 d-flex justify-content-between pb-1" >
      <h1 class="text-black">Client & Stores{{ search }}</h1>
      <div>
        <!-- <button class="btn notofication-btn mr-1">
          <img src="../../../assets/images/client/filter.svg" alt="Snow" />  
          </button> -->

        <button id="popover-button-bottom"   href="#" tabindex="0"  type="button" class="btn notofication-btn mr-1">
          <div>
            <img src="../../../assets/images/client/filter.svg" alt="Snow" />
          </div>
        </button>

        <b-popover ref="popover" target="popover-button-bottom" triggers="hover" placement="Left"
          class="custom-popover pop-btn">
          <div class="center custom-vs-select" style="border: none;width:5cm">
            <v-select :searchable="false" v-model="statusFilter" class="" @input="getClientStore" shadow :clearable="false"
              placeholder="Select" :options="['All', 'Active', 'Inactive']">
              <!-- <v-option class="d-none"
                >Status</v-option -->
              <!-- > -->
              <!-- <v-option label="All" value="all">All</v-option>
              <v-option label="Active" value="active">Active</v-option>
              <v-option label="Inactive" value="inactive">Inactive</v-option> -->
            </v-select>
          </div>
        </b-popover>

        <button type="button" v-b-tooltip.hover.v-primary @click="exportToCSV" title="Export CSV"
          variant="outline-primary" class="btn notofication-btn mr-1">
          <img src="../../../assets/images/client/export.svg" alt="Snow" />
        </button>

        <button id="popover-button-variant" :style="popoverShow ? 'background-color: black;' : ''" href="#" tabindex="0" v-b-tooltip.hover.v-primary title="Select All" 
          type="button" class="btn notofication-btn mr-3" @click="onPopovcerOpen()">
          <div>
            <img v-if="!popoverShow" id="task1" src="../../../assets/images/client/task-square.svg" alt="Snow" />

            <img v-else id="task2" src="../../../assets/images/client/task-light.svg" alt="Snow" />
          </div>
        </button>
        <div v-if="popoverShow" class="popover-manual">
          <div class="popover-inner">
            <button @click="onPopovcerOpen" class="notofication-btn" style="color: #408dff; margin: 5px" size="sm">
            Cancel
          </button>

          <button @click="HandelClickMultipleDublicate()"  v-b-tooltip.hover.v-primary title="Copy" type="button" class="btn notofication-btn" style="background: #f4f9fd; margin: 8px">
            <img src="../../../assets/images/client/copy.png" alt="Snow" />
          </button>
          <button  @click="HandelClickMultipleEnableDisable()" type="button" v-b-tooltip.hover.v-primary title="Active-Inactive" class="btn notofication-btn ml-1" style="background: #f4f9fd; margin: 8px">
            <img src="../../../assets/images/client/circle.svg" alt="Snow" />
          </button>
          <button type="button" @click="HandelClickMultipleDelete()" class="btn notofication-btn" v-b-tooltip.hover.v-danger title="Delete"
            style="background-color: rgba(246, 81, 96, 0.135735); margin: 8px">
            <img src="../../../assets/images/client/delete.png" alt="Snow" />
          </button>
        </div>
        <div class="popup-edge">
          </div>
        </div>

        <button type="button" class="btn btn-primary"  style="border-radius: 16px; padding: 16px"
          @click="addClientHandelClick()" v-b-modal.modal>
          +&nbsp; Add Client
        </button>

        <b-modal size="lg" ref="modal" id="modal" class="m-2 custom--model">
          <template #modal-header>
            <div class="d-flex align-items-center justify-content-between w-100 ">
              <div style="width:50%;" class="form-row d-flex align-items-center">
                <div>
                  <h2 class="mb-0 ml-1" style="font-weight: 800;">{{edit?'Edit ': 'Add '}} Client</h2>
                </div>
                <div class="ml-2">
                  <b-form-checkbox v-model="status" :checked="status" class="my-checkbox ml-1" 
                    name="check-button" switch>
                    <span class="switch-icon-left">
                      Active
                    </span>
                    <span class="switch-icon-right">
                      Inactive
                    </span>
                  </b-form-checkbox>
                </div>
              </div>
            <b-button @click="closeModal" variant="link" class="closeIcon" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </b-button>
          </div>

          </template>
          <validation-observer ref="submitPrevent" #default="{ invalid }">
            <b-form @submit.prevent="submitForm">
              <div class="form-row">
                <div class="col-md-3">
                  <b-form-group class="mb-2" label="Client Name (English)">
                    <validation-provider #default="{ errors }" name="Client Name (English)" rules="required">
                      <b-form-input v-model="clientNameEng" :state="errors.length > 0 ? false : null"
                        placeholder="Enter Client Name (English)" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-3">
                  <b-form-group class="mb-2" label="Client Name (Arabic)">
                    <validation-provider #default="{ errors }" name="Client Name (Arabic)" rules="required">
                      <b-form-input v-model="clientNameEArb" :state="errors.length > 0 ? false : null"
                        placeholder="Enter Client Name (Arabic)" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group class="mb-2" label="Sequence">
                    <validation-provider #default="{ errors }" name="Sequence" rules="required|integer">
                      <b-form-input type="number" v-model="sequence" :state="errors.length > 0 ? false : null"
                        placeholder="Enter Sequence" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-6">
              <b-form-group class="mb-2" label="Business Category">
                <validation-provider #default="{ errors }" name="Business Category " rules="required">
                  <v-select v-model="category" @input="categoryChange()" multiple label="name"
                    :options="categoryOptions"></v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
                </div>
                <div class="col-md-6">
              <b-form-group class="mb-2" label="Website URL">
                <validation-provider #default="{ errors }" name="Website URL" rules="required">
                  <b-form-input v-model="websiteUrl" :state="errors.length > 0 ? false : null"
                    placeholder="Enter Website URL" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
             </div>
                <div class="col-md-6">
                <div class="row">
                <div class="col-md-12">
                  <b-form-group class="mb-2" label="Description (English)">
                    <validation-provider #default="{ errors }" name="Description (English)" rules="required">
                      <textarea class="form-control" rows="3" v-model="descriptionEng"
                        placeholder="Description English (Max  200 Char) "></textarea>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                
                <div class="col-md-12">
                  <b-form-group class="mb-2" label="Description (Arabic)">
                    <validation-provider #default="{ errors }" name="Description (Arabic)" rules="required">
                      <textarea class="form-control" rows="3" v-model="descriptionArb"
                        placeholder="Description English (Max  200 Char) "></textarea>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                </div>
                </div>
                <div class="col-md-6">
                <div class="row">
                <div class="col-md-12">
              <b-form-group class="mb-2" label="Image URL">
                <validation-provider #default="{ errors }" name="Image URL" rules="required">
                  <b-form-input
                    v-model="imageUrl"
                    placeholder="Enter URL"
                    browse-text="Upload"
                  ></b-form-input>
                  <!-- <b-form-input v-model="imageUrl" :state="errors.length > 0 ? false : null"
                    placeholder="Enter Image url" /> -->
                  <small class="text-danger">{{ errors[0] }}</small>
                  <!-- <a target="_blank" v-if="typeof imageUrl  == 'string'" :href="imageUrl" class="font-weight-bolder"><div  style="text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">{{ imageUrl}}</div></a> -->
                </validation-provider>
              </b-form-group>
              </div>
                <div class="col-md-6">
                  <b-form-group class="mb-2" label="Keyword (English)">
                    <validation-provider #default="{ errors }" name="Keyword (English)" rules="required">
                      <textarea class="form-control" rows="3" v-model="keywordEng"
                        placeholder="Keyword English (Max  200 Char) "></textarea>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group class="mb-2" label="Keyword (Arabic)">
                    <validation-provider #default="{ errors }" name="Keyword (Arabic)" rules="required">
                      <textarea class="form-control" rows="3" v-model="keywordArb"
                        placeholder="Keyword Arabic (Max  200 Char) "></textarea>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                
              <div class="col-md-12 mt-2">
                <button type="button" @click="AddClient" class="btn btn-primary px-4 float-right">{{ edit ? 'Update' :
                  'Add' }}</button>
                <button type="button" class="btn btn-default mr-1 float-right" style="border: 1px solid black;color:black;font-weight:bolder;font-size:15px" data-dismiss="modal"
                  @click="closeModal">
                  Cancel at the moment
                </button>
              </div>
              </div>
              </div>
              </div>
            </b-form>
          </validation-observer>
        </b-modal>

        <b-modal id="showmodal" size="lg" ref="showmodal" class="m-2 custom--model">
          <template #modal-header>
            <!-- <div style="width:80%;padding: 10px 20px;" class="form-row">
              <div class="form-group col-md-12">
                <h2>Client </h2>
              </div>
            </div> -->
            
            <div class="ml-1 mr-1">
                  <div class="d-flex">
                    <div class="mr-2">
                    <b-img v-if="checkURL(getImage(showItem))"
                     v-bind="mainProps" rounded="circle" :src="getImage(showItem)"
                      alt="Circle image" class="d-inline-block" />
                    <b-img v-else v-bind="mainProps" rounded="circle"
                      :src="require('@/assets/images/no-pic.png')" alt="Circle image"
                      class="d-inline-block" />
                      </div>
                      <div>
                         <div class="font-weight-bolder h3 text-black">{{ showItem.name_en }} - {{ showItem.name_ar }}</div> 
                          <a target="_blank" :href="showItem.website" class="text-muted h5 text-black">{{showItem.website ? showItem.website.length > 40 ? showItem.website.slice(0,40)+'...':showItem.website : ''  }}</a>
                          <div class="pt-1">
                            <span v-if="showItem[`is_active_${$store.state.application.id}`]" class="btn-outline-success-view">Active</span>
                            <span v-else class="btn-outline-danger-view">Inactive</span>
                          </div>
                      </div>
                    </div>
                  </div>
            <b-button @click="$refs.showmodal.hide()" variant="link" class="closeIcon" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </b-button>
          </template>
          <b-row class="p-1">
            <b-col md="12">

                </b-col>
            <b-col v-if="showItem.categories" md="4" class="d-flex">
              <div class="w-100">
                <div class="mb-half">Business Category</div>
                <div>
                  <div v-for="item in showItem.categories " :key="item.id" class="font-weight-bolder text-black">
                    {{ item.name }}</div>
                  </div>
              </div>
            </b-col>
            <b-col md="4" class="d-flex">
              <div>
                <div class="mb-half">Sequence</div>
                <div class="font-weight-bolder h4 text-black">{{ showItem.sequence }}</div>
              </div>
            </b-col>
            <b-col md="4" class="d-flex">
              <div>
                <div class="mb-half">Id</div>
                <div class="font-weight-bolder h4 text-black">{{ showItem.id }}</div>
              </div>
            </b-col>
            <b-col md="12">
                <div class="mb-half">Description (English)</div>
                <div class="font-weight-bolder text-black">
                  <!-- <p class="font-weight-bolder">{{ showItem.short_desc_en }}</p> -->
                  <p class="font-weight-bolder">{{ convertToPlainText(showItem.description_en) }}</p>
              </div>
            </b-col>
            <b-col md="12">
                <div class="mb-half">Description (Arabic)</div>
                <div class="font-weight-bolder text-black">
                  <p class="font-weight-bolder">{{ convertToPlainText(showItem.description_ar) }}</p>
              </div>
            </b-col>
            <b-col md="12" class="d-flex">
              <div>
                <div class="mb-half">Keyword (English)</div>
                <div class="font-weight-bolder h4 text-black">{{ showItem.search_keyword_en }}</div>
              </div>
            </b-col>
            <b-col md="12" class="d-flex">
              <div>
                <div class="mb-half">Keyword (Arabic)</div>
                <div class="font-weight-bolder h4 text-black">{{ showItem.search_keyword_ar }}</div>
              </div>
            </b-col>
                <!-- <b-col md="4">
                    <div class="mb-half">Client Name (English)</div>
                    <div class="font-weight-bolder">{{ showItem.name_en }}</div> 
                  </b-col>
                <b-col md="4">
                    
                    <div>Client Name (Arabic)</div>
                    <div class="font-weight-bolder">{{ showItem.name_ar }}</div>
            </b-col> -->
            <!-- <b-col md="4" class="d-flex">
              <div>
                <div class="mb-half">Id</div>
                <div class="font-weight-bolder h4 text-black">{{ showItem.id }}</div>
              </div>
            </b-col> -->
            <!-- <b-col md="4" class="d-flex">
              <div>
              <div class="mb-half">Image Url</div>
               <div class="ml-1 mr-1">
                  <b-img v-if="checkURL(showItem.image)" v-bind="mainProps" rounded="circle" :src="showItem.image"
                    alt="Circle image" class="d-inline-block" />
                  <b-img v-else v-bind="mainProps" rounded="circle"
                    :src="require('@/assets/images/no-pic.png')" alt="Circle image"
                    class="d-inline-block" />
                </div>
              </div>
                </b-col> -->
            <!-- <b-col md="4" class="d-flex align-items-center">
              <div>
                <div class="mb-half">Redirect Url</div>
                <a target="_blank" :href="showItem.redirect_link" class="font-weight-bolder  h4 text-black">{{ showItem.redirect_link
                }}</a>
              </div>
            </b-col> -->
           
            <!-- <b-col md="4" class="d-flex">
              <div>
                <div class="mb-half">Image</div>
                <a target="_blank" :href="showItem.image" class="font-weight-bolder">{{ showItem.image }}</a>
              </div>
            </b-col> -->
          </b-row>
          <!-- <div class="modal-footer1">
            <button type="button" class="btn btn-default mr-1" style="border: 1px solid black"
              @click="$refs.showmodal.hide()">
              Close
            </button>
          </div> -->
        </b-modal>
      </div>
    </div>

    <div v-if="isLoading" style="height: 100vh;" class="d-flex justify-content-center align-items-center">
      <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
    </div>
    <div class="d-flex justify-content-center align-items-center" v-else-if="clientStoreData.length == 0">
      <img style="width:13cm; height:13cm" :src="host + '/NoDataFound.svg'" />
    </div>
    <div v-else class="drop-zone">
      <draggable v-model="clientStoreData" handle=".draggable-task-handle" @start="handleStart" @end="handleEnd" @change="draggableChange($event)" tag="ul"
        class="todo-task-list media-list draggable-task-handle d-inline">
        <b-card no-body class="card-revenue-budget dash-total-card mb-2 drag-el" v-for="(item, index) in clientStoreData"
          :key="index" @dblclick="handelShow(item)">
          <b-row class="p-1">
            <b-col md="5">
              <div class="d-flex align-items-center">
                <div>
                  <b-form-checkbox v-if="popoverShow" @change="checkboxClicked" v-model="checkBoxIds[item.id]" class="mr-0 mt-50" name="is-rtl"
                    inline />
                </div>
                <div class="ml-1 mr-1">
                  <b-img v-if="checkURL(getImage(item))" v-bind="mainProps" rounded="circle" :src="getImage(item)"
                    alt="Circle image" class="d-inline-block" />
                  <b-img v-else v-bind="mainProps" rounded="circle"
                    :src="require('@/assets/images/no-pic.png')" alt="Circle image"
                    class="d-inline-block" />
                </div>
                <div>
                  <div class="font-weight-bolder text-black mb-half">
                    {{ item.name_en }}
                  </div>
                  <div style="max-width: 10cm;">{{ item.website ? item.website.slice(0, 45) : '' }} {{ item.website ?
                    item.website.length > 45 ? '...' : '' : '' }}</div>
                </div>
              </div>
            </b-col>
            <b-col md="3" class="d-flex ">
              <div>
                <div class="mb-half">Category</div>
                <div v-if="item.categories.length>0" class="font-weight-bolder text-black">
                  {{ (categoriesViewMore[item.id] == undefined || categoriesViewMore[item.id] == false ) ? item.categories[0].name : item.categories.map(item => {return item.name}).join(', ') }}
                  
                  <a href="#" class="mr-1" style="color: #3880e9; font-size:12px"  v-if="item.categories.length>1" 
                    @click="categoriesViewMoreHandelClick(item.id)">
                    {{categoriesViewMore[item.id] ? '...View less' : '...View more'}}
                </a>
                </div>
              </div>
            </b-col>
            <b-col md="2" class="d-flex ">
              <div>
                <div class="mb-half">Sequence</div>
                <div class="font-weight-bolder text-black">{{ item.sequence }}</div>
              </div>
            </b-col>
            <b-col md="1" class="d-flex ">
              <div>
                <div class="mb-half">Status</div>
                <div class="font-weight-bolder">
                  <div :class="item[`is_active_${$store.state.application.id}`] == 1 ? 'success-btn' : 'danger-btn'">
                    <strong><span class="boder">{{ item[`is_active_${$store.state.application.id}`] == 1 ? 'Active' : 'Inactive' }}</span></strong>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col md="1" class="
                d-flex
                justify-content-center
                text-black
                align-items-center
              ">
              <div :id="'popover-button-variant' + item.id" href="#"
                style="background: #f4f9fd; padding: 8px; border-radius: 16px">
                <feather-icon icon="MoreVerticalIcon" size="25" />
              </div>
            </b-col>
          </b-row>
          <b-popover :target="'popover-button-variant' + item.id" triggers="hover" class="custom-popover">
            <!-- <button @click="onPopovcerOpen3(item.id)" class="notofication-btn" style="color: #408dff; margin: 5px"
              size="sm">
              Cancel
            </button> -->

            <button type="button" class="btn notofication-btn ml-1" v-ripple.400="'rgba(186, 191, 199, 0.15)'" v-b-tooltip.hover.v-primary title="View"
              @click="handelShow(item)" style="background: rgb(125 133 146 / 13%); margin: 8px">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-info-circle"
                viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path
                  d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
              </svg>
            </button>

            <!-- <button
            type="button"
            class="btn notofication-btn"
            style="background: #f4f9fd; margin: 8px"
          >
            <img src="../../../assets/images/client/copy.png" alt="Snow" />
          </button> -->
            <button type="button" class="btn notofication-btn ml-1" @click="handelEdit(item.id)" v-b-modal.modal v-b-tooltip.hover.v-primary title="Edit"
              style="background: rgb(63 140 254 / 13%); margin: 8px">
              
              <img src="../../../assets/images/svg/Edit.svg" alt="Snow" />
            </button>
            <button type="button" class="btn notofication-btn" @click="handelDelete(item.id)" v-b-tooltip.hover.v-danger title="Delete"
              style="background-color: rgba(246, 81, 96, 0.135735); margin: 8px">
              <img src="../../../assets/images/client/delete.png" alt="Snow" />
            </button>
          </b-popover>
        </b-card>
      </draggable>
      <div class="no-results" :class="{ show: !clientStoreData.length }"></div>
    </div>

    <button v-if="!isLoading && clientStoreData.length" type="button" class="w-auto btn slide-btn">
      <div class="d-flex justify-content-around align-items-center">
        <p class="text-black" style="margin: 0">{{ paginationStart + 1 }} - {{ paginationEnd > paginationLength ?
          paginationLength : paginationEnd }} of {{ paginationLength }}</p>
        <feather-icon class="mx-1" icon="ArrowLeftIcon" @click="paginationHandelClick('back')" size="18" />
        <input v-model="pageNo" class="form-control"
          style="width:70px" @input="pagechange" type="number" />
        <feather-icon class="mx-1" icon="ArrowRightIcon" @click="paginationHandelClick('next')" size="18" />
      </div>
    </button>

    <button v-if="popoverShow && !isLoading" id="seeall-btn" type="button" class="w-auto btn see-btn" @click="selectAllClick()">
      <div class="d-flex justify-content-around align-items-center">
        <p class="text-black" style="margin: 0">{{ selectAllLabel }}</p>
      </div>
    </button>
  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer } from "@validations";
import draggable from "vuedraggable";
import { BImg } from 'bootstrap-vue'
import json2csv from 'json2csv';

import vSelect from 'vue-select';
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
// import { isValidDate } from '@fullcalendar/common';
import { mapState } from 'vuex';
export default {
  components: {
    draggable,
    vSelect,
    quillEditor, BImg,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      mainProps: {
        width: 70,
        height: 70,
        class: 'm1',
      },
      data: {},
      required, integer,
      isLoading: true,
      filter: 'All',
      baseURL: process.env.VUE_APP_BASE_URL,
      host: window.location.origin,
      categoryOptions: [],
      clientStoreData: [],
      clientStoreData1: [],
      popoverShow: false,
      categoriesViewMore:{},
      edit: false,
      selectAllLabel: 'Select All',
      paginationStart: 0,
      pageNo: 1,
      paginationEnd: 10,
      clientNameEng: '',
      clientNameEArb: '',
      category: [],
      category_ids: [],
      descriptionEng: '',
      descriptionArb: '',
      status: '',
      sequence: 0,
      checkBoxIds: {},
      websiteUrl: '',
      imageUrl: '',
      selectAll: false,
      statusFilter: "All",
      paginationLength: 0,
      keywordEng: '',
      showItem: {},
      keywordArb: '',
    };
  },
  created() {
  },
  watch: {
   'sidebar': {
     handler: 'checkIsValid',
     immediate: true, 
   },
  '$store.state.application': {
    handler: 'checkApplication',
    immediate: true,
  },
  },
  mounted() {
    this.$http.post(`${this.baseURL}/client-categories/index`).then((res) => {
      this.categoryOptions = res.data.data
      this.checkBoxIds = {}
    })
  },
  computed: {
      ...mapState(['sidebar']),
    search() {
      let search = this.$store.state.searchData.replace(/\s/g, '').toLowerCase()
      this.getSearch(search);
    }
  },
  methods: {
    checkApplication(){
      this.clientStoreData.map(item => {this.getImage(item)});
    },
    checkIsValid() {
      if (this.sidebar) {
        let is_valid = this.sidebar.some(data => data.name == 'Client & Stores');
        if (!is_valid) {
          this.$router.push('/');
        }
      }
    },
    handleStart(){
      document.body.style.cursor = 'grabbing'; 
    },
    handleEnd(){
      document.body.style.cursor = 'auto';
    },
    categoriesViewMoreHandelClick(id){
    this.categoriesViewMore[id] = !this.categoriesViewMore[id];
    this.$forceUpdate();
    },
    convertToPlainText(quillContent) {
      const parser = new DOMParser();
      const parsedHtml = parser.parseFromString(quillContent, 'text/html');
      // this.plainTextContent = parsedHtml.body.textContent;
      return parsedHtml.body.textContent
    },
    handelShow(item) {
      this.$refs.showmodal.show()
      this.popoverShow = false
      this.showItem = item
    },
    getSearch: _.debounce(function (search) {
      this.pageNo = 1
      this.paginationStart = 0
      this.paginationEnd = 10
      // if(search){
      this.isLoading = true
      this.$http.post(`${this.baseURL}/client/search`, { statusFilter: this.statusFilter, search: search, pageno: this.pageNo },
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((res) => {
          console.log(res, 'res')
          this.clientStoreData = res.data.data
          this.paginationLength = res.data.totaldata
          this.clientStoreData1 = res.data.data
          this.isLoading = false
        })
      // }
    }, 1000),
    checkboxClicked(){
    this.$forceUpdate();
    },
    exportToCSV() {
      // Convert the data to CSV format using json2csv library
      let array = []
      this.clientStoreData.map(item => {
        let data = {
          'Name (English)': item.name_en,
          'Name (Arabic)': item.name_ar,
          Category: item.categories.map((item) => { return item.name }).join(", "),
          'Description (English)': item.description_en,
          'Description (Arabic)': item.description_ar,
          'Active': item[`is_active_${this.$store.state.application.id}`],
          Sequence: item.sequence,
          Website: item.website,
          Image: item.image,
          'Search Keyword (English)': item.search_keyword_en,
          'Search Keyword (Arabic)': item.search_keyword_ar,
        }
        array.push(data);
      })
      const csvData = json2csv.parse(array)
        ;
      const link = document.createElement('a');
      link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData);
      link.download = 'ClientAndStore.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    closeModal() {
      this.$refs['modal'].hide()
    },
    handelChangeFilter() {
      console.log(this.filter)
      if (this.filter == "All") {
        this.clientStoreData = this.clientStoreData1
      }
      else if (this.filter == "Active") {
        let data = []
        data = this.clientStoreData1.filter(item => item[`is_active_${this.$store.state.application.id}`] == 1)
        this.clientStoreData = data
      }
      else if (this.filter == "Inactive") {
        let data = []
        data = this.clientStoreData1.filter(item => item[`is_active_${this.$store.state.application.id}`] == 0)
        this.clientStoreData = data
      }
    },
    async addClientHandelClick() {
      this.edit = false
      this.editItem = {}
      this.clientNameEng = ''
      this.clientNameEArb = ''
      this.category_ids = []
      this.category = []
      this.descriptionEng = ''
      this.descriptionArb = ''
      this.status = ''
      this.sequence = 0
      this.sequence1 = 0
      this.websiteUrl = ''
      this.imageUrl = ''
      this.keywordEng = ''
      this.keywordArb = ''
      this.popoverShow = false
      this.$http.post(`${this.baseURL}/client/get-sequence`).then((json) => {
        console.log(json.data.sequence, "data-=-=--")
        this.sequence = json.data.sequence
        this.sequence1 = json.data.sequence
      })
    },
    handelEdit(id) {
      this.edit = true

      this.popoverShow = false
      this.$http.post(`${this.baseURL}/client/view/${id}`,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((json) => {
          this.editItem = json.data.data
          this.clientNameEng = this.editItem.name_en,
            this.clientNameEArb = this.editItem.name_ar,
            this.category = this.editItem.categories,
            this.descriptionEng = this.editItem.description_en,
            this.descriptionArb = this.editItem.description_ar,
            this.sequence = parseInt(this.editItem.sequence),
            this.sequence1 = 0
              this.$http.post(`${this.baseURL}/client/get-sequence`).then((json) => {
                console.log(json.data.sequence, "data-=-=--")
                this.sequence1 = json.data.sequence - 1
              })
            this.websiteUrl = this.editItem.website,
            this.imageUrl = this.getImage(this.editItem,'all')?.image,
            this.imageId = this.getImage(this.editItem,'all')?.id,
            this.keywordEng = this.editItem.search_keyword_en,
            this.keywordArb = this.editItem.search_keyword_ar,
            this.status = this.editItem[`is_active_${this.$store.state.application.id}`] ? true : false,
            console.log(this.editItem)
          this.categoryChange()
        })
      //  this.$router.push(`/roles/editrole/${id}`)
    },
    getImage(item,value){
      if(item.allImages !== undefined){
          if(this.$store.state.application.id == 1){
          let data = item.allImages.filter(item1 => (item1.asset == 'Mobile Category'))
          if(data.length > 0){
             if(value == 'all'){  
              return data[0]
              }else{
                return data[0].image.replace(/\s/g,'%20')
              }
            }else{
              return undefined
            }
          }
          else if(this.$store.state.application.id == 2){
          let data = item.allImages.filter(item1 => (item1.asset == 'Web Slider' || item1.asset == 'Web Coupon Banner'))
          if(data.length > 0){
            if(value == 'all'){  
          return data[0]
            }else{
              return data[0].image.replace(/\s/g,'%20')
            }
          }else{
            return undefined
          }
          }
          else if(this.$store.state.application.id == 3){
          let data = item.allImages.filter(item1 => item1.asset == 'Coupon Rush Client')
          if(data.length > 0){
            if(value == 'all'){  
          return data[0]
            }else{
              return data[0].image.replace(/\s/g,'%20')
            }
          }else{
            return undefined
          }
          }
          else if(this.$store.state.application.id == 4){
          let data = item.allImages.filter(item1 => item1.asset == 'Mobile Category')
          if(data.length > 0){
            if(value == 'all'){  
          return data[0]
            }else{
              return data[0].image.replace(/\s/g,'%20')
            }
          }else{
            return undefined
          }
          }
          // else if(this.$store.state.application.id == 5){
          //   let data = item.allImages.filter(item1 => (item1.asset == 'Old SC Web Coupon Banner'))
          // if(data.length > 0){
          //   if(value == 'all'){  
          //  return data[0]
          //   }else{
          //     return data[0].image.replace(/\s/g,'%20')
          //   }
          // }else{
          //   return undefined
          // }
          // }
          else if(this.$store.state.application.id == 6){
          let data = item.allImages.filter(item1 => (item1.asset == 'Coupon Rush Client'))
          if(data.length > 0){
            if(value == 'all'){  
          return data[0]
            }else{
              return data[0].image.replace(/\s/g,'%20')
            }
          }else{
            return undefined
          }
          }
          else if(this.$store.state.application.id == 7){
          let data = item.allImages.filter(item1 => (item1.asset == 'Lala Web Slider' || item1.asset == 'Lala Web Coupon Banner'))
          if(data.length > 0){
            if(value == 'all'){  
          return data[0]
            }else{
              return data[0].image.replace(/\s/g,'%20')
            }
          }else{
            return undefined
          }
          }
          else if(this.$store.state.application.id == 8){
            let data = item.allImages.filter(item1 => (item1.asset == 'Key Rush Coupon Detail' || item1.asset == 'Key Rush Coupon') ||  item1.asset == 'Key Rush Client')
          if(data.length > 0){
            if(value == 'all'){  
          return data[0]
            }else{
              return data[0].image.replace(/\s/g,'%20')
            }
          }else{
            return undefined
          }
          }else{
            return undefined
          }
        }else{
          return undefined
        }
          this.$forceUpdate();
    },
    checkURL(url) {
      const pattern = /^(ftp|http|https):\/\/[^ "]+$/;
      return pattern.test(url ? url.replace(/\s/g,'%20') : url);
    },
    pagechange() {
      if (this.pageNo > (this.paginationLength / 10)) {
        this.pageNo = parseInt(this.paginationLength / 10)
      }
      if (this.pageNo == 0) {
        this.pageNo = 1
      }
      this.paginationStart = (this.pageNo * 10) - 10
      this.paginationEnd = (this.pageNo * 10)
      let search = this.$store.state.searchData
      if (search) {
        this.searchPaginationHandelClick(search)
      } else {
        this.normalPaginationHandelClick(search)
      }
    },
    searchPaginationHandelClick: _.debounce(function (search) {
      this.isLoading = true
      this.$http.post(`${this.baseURL}/client/search`, { statusFilter: this.statusFilter, search: search, pageno: this.pageNo }).then((res) => {
        this.clientStoreData = res.data.data
        // this.pageNo=res.data.page
        this.selectAllLabel = 'Select All'
        this.checkBoxIds = {}
        this.paginationLength = res.data.totaldata
        this.clientStoreData1 = res.data.data
        this.isLoading = false
      })
    }, 1000),
    normalPaginationHandelClick: _.debounce(function () {
      this.isLoading = true
      this.$http.post(`${this.baseURL}/client/index`, { statusFilter: this.statusFilter, pageno: this.pageNo }).then((res) => {
        this.clientStoreData = res.data.data
        // this.pageNo=res.data.page
        this.paginationLength = res.data.totaldata
        this.selectAllLabel = 'Select All'
        this.checkBoxIds = {}
        this.clientStoreData1 = res.data.data
        this.isLoading = false
      })
    }, 1000),
    paginationHandelClick(value) {
      let search = this.$store.state.searchData
      if (value == 'back') {
        if (this.paginationStart > 1) {
          this.pageNo--
          this.paginationStart = this.paginationStart - 10
          this.paginationEnd = this.paginationEnd - 10
          if (search) {
            this.searchPaginationHandelClick(search, value)
          } else {
            this.normalPaginationHandelClick(search, value)
          }
        }
      }
      else if (value == 'next') {
        if (this.paginationEnd < this.paginationLength) {
          this.pageNo++
          this.paginationStart = this.paginationStart + 10
          this.paginationEnd = this.paginationEnd + 10
          if (search) {
            this.searchPaginationHandelClick(search, value)
          } else {
            this.normalPaginationHandelClick(search, value)
          }
        }
      }
    },
    categoryChange() {
      this.category_ids = []
      this.category.map(item => {
        this.category_ids.push(item.id)
      })
    },
    HandelClickMultipleDelete() {
      let arr = []
      Object.keys(this.checkBoxIds).map((key) => {
        if (this.checkBoxIds[key] == true) {
          arr.push(key)
        }
      });
      if (arr.length > 0) {
        this.isLoading =true
        this.$http.post(`${this.baseURL}/client/multi-delete`, { ids: arr },
          {
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true
          }).then((res) => {
            this.$swal({
              title: "Deleted",
              text: res.data.message
                ? `${res.data.message}`
                : res.data.success
                  ? `${res.data.success}`
                  : "Deleted",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.getClientStore()
            this.checkBoxIds = {}
            this.popoverShow= false
            this.$forceUpdate()
          })
      } else {
        this.$swal({
          title: "Warning",
          text: "Select atleast one item",
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-warning",
          },
          buttonsStyling: false,
        });
      }
    },
   async handelClickFile(e){
      
      const formData = new FormData();
      formData.append("image", e);
      formData.append("type", 'client');
      this.$http.post(`${this.baseURL}/file-upload/create`, formData,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((json) => {
          this.imageUrl = json.data.data.image
        })
    },
    draggableChange(e) {
      console.log(e.moved, "e0-0=-=")
      let search = this.$store.state.searchData
      console.log(this.clientStoreData1[e.moved.newIndex].sequence, this.clientStoreData1[e.moved.oldIndex].sequence, "e0-0=-=")
      console.log(e.moved.newIndex, e.moved.oldIndex, "e0-0=-=")
      let data = {
        newIndex: this.clientStoreData1[e.moved.newIndex].sequence,
        oldIndex: this.clientStoreData1[e.moved.oldIndex].sequence,
        pageno: this.pageNo,
        statusFilter: this.statusFilter
      }
      if (search) { data = { ...data, search: search } }
      this.$http.post(`${this.baseURL}/client/sequence-change`, data,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((res) => {
          console.log(res.data.data, "res-=-=")
          this.clientStoreData = []
          this.clientStoreData1 = []
          this.clientStoreData = res.data.data
          this.paginationLength = res.data.totaldata
          this.clientStoreData1 = res.data.data
        })
    },
    HandelClickMultipleEnableDisable(){
      let arr = []
      Object.keys(this.checkBoxIds).map((key) => {
        if (this.checkBoxIds[key] == true) {
          arr.push(key)
        }
      });
      if (arr.length > 0) {
        this.isLoading =true
        this.$http.post(`${this.baseURL}/client/multi-enable-disable`, { ids: arr },
          {
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true
          }).then((res) => {
            this.$swal({
              title: "Updated",
              text: res.data.message
                ? `${res.data.message}`
                : res.data.success
                  ? `${res.data.success}`
                  : "Updated",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.checkBoxIds = {}
            this.popoverShow= false
            this.getClientStore()
            this.selectAllLabel = 'Select All'
            this.$forceUpdate()
          })
      } else {
        this.$swal({
          title: "Warning",
          text: "Select atleast one item",
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-warning",
          },
          buttonsStyling: false,
        });
      }
    },
    HandelClickMultipleDublicate(){
      let arr = []
      Object.keys(this.checkBoxIds).map((key) => {
        if (this.checkBoxIds[key] == true) {
          arr.push(key)
        }
      });
      if (arr.length > 0) {
      this.isLoading = true
        this.$http.post(`${this.baseURL}/client/copy`, { ids: arr },
          {
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true
          }).then((res) => {
            if (res.data.status == 201) {
            
              this.$swal({
              title: "Success",
              text: res.data.message
                ? `${res.data.message}`
                : res.data.success
                  ? `${res.data.success}`
                  : "Success",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.getClientStore()
            this.popoverShow= false
            this.checkBoxIds = {}
            this.selectAllLabel = 'Select All'
            this.$forceUpdate()
            } else {
              let text = Object.keys(res.data.data).length > 0 ? Object.keys(res.data.data).map(item => {return `${item}:${res.data.data[item]}`}) : res.data.message
                  ? `${res.data.message}` 
                    : "Error"
              this.$swal({
                title: "Error!!",
                text: text,
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
          }
        })
      } else {
        this.$swal({
          title: "Warning",
          text: "Select atleast one item",
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-warning",
          },
          buttonsStyling: false,
        });
      }
    },
    handelDelete(id) {
      this.$http.post(`${this.baseURL}/client/delete`, { id: id },
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((res) => {
          this.$swal({
            title: "Deleted",
            text: res.data.message
              ? `${res.data.message}`
              : res.data.success
                ? `${res.data.success}`
                : "Deleted",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.getClientStore()
          this.selectAllLabel = 'Select All'
          this.checkBoxIds = {}
          this.$forceUpdate()
        })
    },
    async AddClient() {
      let image = {}
     await this.$http.post(`${this.baseURL}/client/get-image-type`).then((json) => {
        console.log(json.data.data)
        json.data.data.map(item => {
          if(this.$store.state.application.id == 1 && item.id == 4){
           let data = {
            asset:item.value,
            image_path:this.imageUrl,
            image_id:this.imageId == undefined ? 0 : this.imageId,
           }
           image = data
          }else if(this.$store.state.application.id == 2 && item.id == 4){
           let data = {
            asset:item.value,
            image_path:this.imageUrl,
            image_id:this.imageId == undefined ? 0 : this.imageId,
           }
           image = data
          }else if(this.$store.state.application.id == 3 && item.id == 6){
           let data = {
            asset:item.value,
            image_path:this.imageUrl,
            image_id:this.imageId == undefined ? 0 : this.imageId,
           }
           image = data
          }else if(this.$store.state.application.id == 4 && item.id == 1){
           let data = {
            asset:item.value,
            image_path:this.imageUrl,
            image_id:this.imageId == undefined ? 0 : this.imageId,
           }
           image = data
          }else if(this.$store.state.application.id == 6 && item.id == 6){
           let data = {
            asset:item.value,
            image_path:this.imageUrl,
            image_id:this.imageId == undefined ? 0 : this.imageId,
           }
           image = data
          }else if(this.$store.state.application.id == 7 && item.id == 9){
           let data = {
            asset:item.value,
            image_path:this.imageUrl,
            image_id:this.imageId == undefined ? 0 : this.imageId,
           }
           image = data
          }else if(this.$store.state.application.id == 8 && item.id == 13){
           let data = {
            asset:item.value,
            image_path:this.imageUrl,
            image_id:this.imageId == undefined ? 0 : this.imageId,
           }
           image = data
          }
        })
      });
      let data = {
        name_en: this.clientNameEng,
        name_ar: this.clientNameEArb,
        category_id: this.category_ids,
        description_en: this.descriptionEng,
        description_ar: this.descriptionArb,
        [`is_active_${this.$store.state.application.id}`]: this.status ? 1 : 0,
        is_active: this.status ? 1 : 0,
        sequence: this.sequence,
        website: this.websiteUrl,
        image: image,
        search_keyword_en: this.keywordEng,
        search_keyword_ar: this.keywordArb,
      }
      if (this.edit) {
        data = { ...{ id: this.editItem.id }, ...data }
      }
      if(this.sequence > this.sequence1){
        this.$swal({
                title: "Warning!!",
                text: `Sequence must be Lower than ${this.sequence1}`,
                icon: "warning",
                customClass: {
                  confirmButton: "btn btn-warning",
                },
                buttonsStyling: false,
              });
      }else{
      this.$refs.submitPrevent.validate().then((success) => {
        if(!success){
        this.$swal({
                title: "Warning!!",
                text: `Please Select Require Fields`,
                icon: "warning",
                customClass: {
                  confirmButton: "btn btn-warning",
                },
                buttonsStyling: false,
              });
        }else{
          
        this.$http.post(`${this.baseURL}/client/${this.edit ? 'update' : 'create'}`, data,
          {
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true
          }).then((json) => {
            if (json.data.status == 'error') {
              let text = Object.keys(json.data.data).length > 0 ? Object.keys(json.data.data).map(item => {return `${item}:${json.data.data[item]}`}) : json.data.message
                  ? `${json.data.message}` 
                    : "Error"
              this.$swal({
                title: "Error!!",
                text: text,
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            } else {
              this.getClientStore()
              this.$store.state.search = ''
              this.checkBoxIds = {}
              this.$refs['modal'].hide()
              this.$swal({
                title: "Submitted",
                text: json.data.message
                  ? `${json.data.message}`
                  : json.data.success
                    ? `${json.data.success}`
                    : "Submitted SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            }
          })
          
        }
      })
    }
    },
    selectAllClick() {
      let data = Object.keys(this.checkBoxIds).filter(item => this.checkBoxIds[item])
      if (this.selectAllLabel == 'Deselect All') {
        this.selectAllLabel = 'Select All'
        this.checkBoxIds = {}
      } else {
        this.selectAllLabel = 'Deselect All'
        this.clientStoreData.map(item => {
          this.checkBoxIds[item.id] = true
        })
      }
      this.$forceUpdate()
    },
    // CheckBoxHandelClick(id) {
    //   console.log(this.checkBoxIds[id])
    //   this.checkBoxIds[id] = this.checkBoxIds[id] == undefined ? false : this.checkBoxIds[id]
    //   this.checkBoxIds[id] = this.checkBoxIds[id] == true ? false : true
    //   console.log(this.checkBoxIds[id])

    //   this.$forceUpdate()
    // },
    onPopovcerOpen() {
      this.popoverShow = !this.popoverShow;
    },
    async getClientStore() {
      this.isLoading = true
      this.pageNo = 1
      this.paginationEnd = 10,
      this.paginationStart = 0,
      this.$http.post(`${this.baseURL}/client/index`, { statusFilter: this.statusFilter, pageno: this.pageNo }).then((res) => {
        console.log(res.data.data, "res-=-=")
        this.clientStoreData = res.data.data
        // this.pageNo=res.data.page
        this.paginationLength = res.data.totaldata
        this.clientStoreData1 = res.data.data
        this.isLoading = false
      })

      // let data = await getClientStoreData();
      // console.log(data,"data=====");
    }
  },
};
</script>

<style lang="scss">  
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
// .draggable-task-handle {
//     transform: translateY(-50%);
//     visibility:visible;
//     cursor: move;

//     .todo-task-list .todo-item:hover & {
//       visibility: hidden;
//     }
// }
.drag-el:active {
  cursor: grabbing;
}

.sortable-chosen {
  margin-right: 8px; /* Adj Sprii https://www.sprii.ae CategorySequence7StatusInactiveust the margin for spacing between the icon and text */
  /* Add any additional styling for the custom icon */
}
.dragging {
  cursor: grabbing !important; /* Update this with your desired cursor */
}

.popup-edge{
position: absolute;
width:50px;
height: 50px;
background-color: #ffffff;
right:0;
top:13px;
transform: rotate(45deg);
}
.popover-manual{
  position: absolute;
    right: 6.5cm !important;
    background-color: white;
    border-radius: 26px;
    padding: 5px 15px;
    left: auto;
    top:100px !important;
    // z-index: 10000;
}
.popover-inner{
  display: flex;
  z-index: 1;
  position: relative;
}
.success-btn {
  width: 58px;
  padding: 3px 5px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #0ac947;
  border: 1px solid #0ac947;
  border-radius: 4px;
}

.danger-btn {
  width: 58px;
  padding: 3px 5px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #c90a0a;
  border: 1px solid #c90a0a;
  border-radius: 4px;
}

.mb-half {
  margin-bottom: 5px;
}

.notofication-btn {
  background-color: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  padding: 12px 12px;
}

.btn-outline-danger-view{
  width: 58px;
  padding: 0px 0px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight:500;
  font-size: 16px;
  line-height: 12px;
  text-align: center;
  color: #ea5455;
  // border: 1px solid #ea5455;
  border-radius: 4px;
}

.btn-outline-success-view{
  width: 58px;
  padding: 0px 0px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight:500;
  font-size: 18px;
  line-height: 12px;
  text-align: center;
  color: #0ac947;
  // border: 1px solid #0ac947;
  border-radius: 4px;
}
.slide-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 18px;
  padding: 15 px;
  margin: 0;
  min-width: 250px;
  float: right;
}

.see-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  width: 125px;
}

.bs-popover-left {
  border-radius: 14px !important;
  border: transparent;
  min-width: 140px !important;
  max-width: 350px !important;
}

.bs-popover-left .popover-body {
  display: flex;
  justify-content: space-between;
  border: none;
}

.bs-popover-bottom {
  background-color: #ffffff;
  // height: 190px;
  border: transparent;
  border: none;
  border-radius: 14px;
}
.popover-body {
  border: none !important;
}

.popover.bs-popover-bottom .arrow:after {
  border-bottom-color: #ffffff;
}

.vs-select__options__content {
  background-color: #ffffff !important;
}

.pop-btn {
  padding: 0 !important;
}


.modal-body {
  padding: 0px 40px !important;
}

.form-control {
  border-radius: 14px;
}

.modal-content {
  border-radius: 24px;
}

.modal-header {
  border-radius: 24px;
  height: 77px;
}

textarea.form-control {
  min-height: 150px !important;
}

.popover1-body {
  padding: 0;
}
legend{
  font-weight: 600;
}
.modal-footer {
  display: none;
}

.modal-footer1 {
  display: flex;
  float: right;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.8rem 1.4rem;
}

.quill-height{
  height:60px
}
</style>
